<template>
    <b-modal
      v-model="data.showModal"
      size="md"
      button-size="sm"
      modal-class="mixing-modal"
      title-class="w-full"
      footer-class="w-full"
      no-fade
      @show="onShow"
      no-close-on-esc
      scrollable
    >
      <!-- Modal header -->
      <div slot="modal-title">
        <div class="flex w-full justify-between">
          Corob
        </div>
      </div>

      <!-- Modal content -->
      <div>
        <b-tabs pills v-model="recipeIndex">
          <b-tab
            v-for="recipe in recipes"
            :key="recipe.value"
            :title="recipe.text"
          ></b-tab>
        </b-tabs>

        <div class="mt-3">
          <b-form-checkbox
            v-for="(color,key) in colors[selectedRecipe]"
            :key="`color-${color.code}`"
            :name="`color-${color.code}`"
            v-model="colors[selectedRecipe][key].selected"
            class="my-2"
          >
            <div class="flex gap-4 items-center">
              <span class="color-box" :class="color.class"></span> {{ color.name }}
            </div>
          </b-form-checkbox>
        </div>
      </div>

      <!-- Modal footer -->
      <div slot="modal-footer" class="w-full d-flex gap-4 justify-content-between items-center">
        <div class="flex w-full justify-content-between">
          <b-btn
            size="sm"
            variant="primary"
            :disabled="isLoading"
            @click="onApply"
          >
            Apply
          </b-btn>

          <div class="flex gap-4 items-center">
            <button-select
              v-model="mixingMode"
              :options="mixingModes"
              :disabled="filters.mixing_mode_disabled"
              size="sm"
            />

            <b-btn
              size="sm"
              variant="white"
              :disabled="isLoading"
              @click="onReset"
            >
              Reset
            </b-btn>
          </div>
        </div>
      </div>
    </b-modal>
  </template>

<script>
const ButtonSelect = () => import('@/components/global/ButtonSelect.vue');

export default {
  name: 'MixingModal',
  components: {
    ButtonSelect,
  },
  props: {
    data: Object,
    filters: Object,
  },
  computed: {
    isLoading() {
      return this.loadingCount > 0;
    },
    selectedRecipe() {
      return this.recipes[this.recipeIndex].value;
    },
  },
  data() {
    return {
      loadingCount: 0,
      recipeIndex: 0,
      recipes: [
        { text: 'French', value: 'french' },
        { text: 'Bekro', value: 'bekro' },
      ],
      mixingMode: '',
      mixingModes: [
        { text: 'Only', value: 'exclusive', title: 'Recipes can only inlude any of the selected colors.' },
        { text: 'Contains', value: 'inclusive', title: 'Recipes must include any of the selected colors.' },
      ],
      colors: {
        french: [
          {
            code: 'red_20002',
            class: 'd858',
            name: 'Red 20002',
            selected: false,
          },
          {
            code: 'yellow_20003',
            class: 'd879',
            name: 'Yellow 20003',
            selected: false,
          },
          {
            code: 'blue_20004',
            class: 'd878',
            name: 'Blue 20004',
            selected: false,
          },
          {
            code: 'black_20005',
            class: 'd1972',
            name: 'Black 20005',
            selected: false,
          },
          {
            code: 'green_20006',
            class: 'd880',
            name: 'Green 20006',
            selected: false,
          },
          {
            code: 'brown_20008',
            class: 'd883',
            name: 'Brown 20008',
            selected: false,
          },
          {
            code: 'orange_20009',
            class: 'd29200',
            name: 'Orange 20009',
            selected: false,
          },
          {
            code: 'magenta_20010',
            class: 'd30000',
            name: 'Magenta 20010',
            selected: false,
          },
          {
            code: 'ld24405',
            class: 'd14112',
            name: 'Cobalt Blue LD24405',
            selected: false,
          },
          {
            code: 'd23311',
            class: 'ld13206',
            name: 'Seagreen D23311',
            selected: false,
          },
        ],
        bekro: [
          {
            code: 'b12802_01_37',
            class: 'b12802_01_37',
            name: '12802-01-37',
            selected: false,
          },
          {
            code: 'b11169_71_11',
            class: 'b11169_71_11',
            name: '11169-71-11',
            selected: false,
          },
          {
            code: 'b11180_39_11',
            class: 'b11180_39_11',
            name: '11180-39-11',
            selected: false,
          },
          {
            code: 'b11189_51_11',
            class: 'b11189_51_11',
            name: '11189-51-11',
            selected: false,
          },
          {
            code: 'b11939_30_11',
            class: 'b11939_30_11',
            name: '11939-30-11',
            selected: false,
          },
          {
            code: 'b12001_50_16',
            class: 'b12001_50_16',
            name: '12001-50-16',
            selected: false,
          },
          {
            code: 'b12317_27_16',
            class: 'b12317_27_16',
            name: '12317-27-16',
            selected: false,
          },
          {
            code: 'b12609_10_16',
            class: 'b12609_10_16',
            name: '12609-10-16',
            selected: false,
          },
          {
            code: 'b12901_60_16',
            class: 'b12901_60_16',
            name: '12901-60-16',
            selected: false,
          },
          {
            code: 'b12904_70_16',
            class: 'b12904_70_16',
            name: '12904-70-16',
            selected: false,
          },
          {
            code: 'b12908_30_16',
            class: 'b12908_30_16',
            name: '12908-30-16',
            selected: false,
          },
          {
            code: 'b12910_10_36',
            class: 'b12910_10_36',
            name: '12910-10-36',
            selected: false,
          },
          {
            code: 'b14046_60_16',
            class: 'b14046_60_16',
            name: '14046-60-16',
            selected: false,
          },
          {
            code: 'b15049_50_16',
            class: 'b15049_50_16',
            name: '15049-50-16',
            selected: false,
          },
          {
            code: 'b15053_34_36',
            class: 'b15053_34_36',
            name: '15053-34-36',
            selected: false,
          },
        ],
      },
    };
  },
  methods: {
    onShow() {
      const keys = Object.keys(this.colors);
      keys.forEach((key) => {
        this.colors[key].forEach((color) => {
          if (this.data.mixing[key].includes(color.code)) {
            color.selected = true;
          }
        });
      });
      this.mixingMode = this.filters.mixing_mode;
    },
    onReset() {
      const keys = Object.keys(this.colors);
      keys.forEach((key) => {
        this.colors[key].forEach((color) => {
          color.selected = false;
        });
      });
      if (!this.filters.mixing_mode_disabled) {
        this.mixingMode = 'exclusive';
      }
    },
    onClose() {
      this.data.showModal = false;
      this.$emit('close');
    },
    onApply() {
      const keys = Object.keys(this.colors);
      const mixing = {};
      keys.forEach((key) => {
        mixing[key] = this.colors[key]
          .filter((color) => color.selected === true)
          .map((color) => color.code);
      });
      this.filters.mixing_mode = this.mixingMode;
      this.$emit('apply', mixing);
      this.onClose();
    },
  },
};
</script>

<style lang="scss">
.mixing-modal {
  .color-box {
    width: 15px;
    height: 15px;
  }
}

</style>
